import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/video/api/v1'
const baseUrl1 = config.baseUrl + '/course/api/v1'

export default {
  enterVirtualRoom(userId, userType, virtualRoomId) {
    return axios.post(`${baseUrl}/virtualRoom/enterVirtualRoom?userId=${userId}&virtualRoomId=${virtualRoomId}&userType=${userType}`)
  },
  getVirtualRoom(sectionId) {
    // 直播获取信息新接口
    return axios.get(`${baseUrl1}/section/live/start?sectionId=${sectionId}`)
  },
  getVirtual(sectionId) {
    return axios.get(`${baseUrl1}/section/live/info?sectionId=${sectionId}`)
  },
  endLive(sectionId) {
    // 直播获取信息新接口
    return axios.get(`${baseUrl1}/section/live/quit?sectionId=${sectionId}`)
  },
  getCreateRealRoomBill(userId, userType, virtualRoomId) {
    return axios.get(`${baseUrl}/webRtc/getCreateRealRoomBill?userId=${userId}&virtualRoomId=${virtualRoomId}&userType=${userType}`)
  },
  saveRealRoom(data) {
    return axios.post(`${baseUrl}/realRoom/saveRealRoom`, data)
  },
  dismissRealRoom(virtualRoomId, userId) {
    return axios.get(`${baseUrl}/realRoom/dismissRealRoom?virtualRoomId=${virtualRoomId}&userId=${userId}`)
  },
  getLiveSectionDetail(sectionId) {
    return axios.get(`${config.baseUrl}/course/api/v1/section/live/details/${sectionId}`)
  }
}
