<template>
  <div class="memberList">
    <vueScroll :ops="ops">
      <div class="member" v-for="(m, index) in memberList" :key="index">
        <Avatar :src="m.avatar" style="width: 26px;height: 26px" />
        <p style="margin-left: 15px">{{ m.nick }}</p>
      </div>
    </vueScroll>
  </div>
</template>

<script>
import vueScroll from 'vuescroll'
export default {
  components: { vueScroll },
  data: function() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#fff',
          opacity: 0.6, // 滚动条透明度
          onlyShowBarOnScroll: false, // 是否只在滚动时bar
          showDelay: 0 // 鼠标离开该区域多长时间隐藏
        }
      }
    }
  },
  props: {
    ticSdk: {
      type: Object
    },
    groupId: {
      type: String
    },
    isLogin: {
      type: Boolean
    },
    memberList: {
      type: Array
    }
  }
}
</script>

<style lang="less" scoped>
.memberList {
  font-size: 14px;
  height: 100%;
  max-height: 650px;
  .member {
    display: flex;
    margin: 10px;
  }
}
</style>
