<template>
  <div class="live-container">
    <div class="top-bar">
      <div class="live-header">
        <div style="display: flex;align-items: center">
          <div class="back-btn" @click="$router.go(-1)">
            <JYIcon href="#icon-back-arrow" @click="$router.go(-1)" />
          </div>
          {{ section.name }}
          <span style="font-size: 12px;margin-left: 15px;" :style="{ color: error ? '#e52436' : '#78cf7d' }">{{ status }}</span>
        </div>
        <div class="opt-container">
          <Button v-if="!isStart" type="primary" @click="beginLive" size="small" :loading="loading">开始直播</Button>
          <Button v-if="isStart" type="error" size="small" :loading="loading" @click="endLive">结束直播</Button>
        </div>
      </div>
      <UserBar />
    </div>

    <div class="content">
      <div class="live-content" ref="videoContainer">
        <div class="live-option">
          <!--          <div class="option" @click="handleBoardClick">-->
          <!--            <JYIcon href="#icon-baiban"/>-->
          <!--            <p>白板</p>-->
          <!--          </div>-->
          <!--          <div v-if="isPushCamera" class="option" style="margin-top: 20px" @click="handleScreenClick">-->
          <!--            <JYIcon href="#icon-gongxiangpingmu"/>-->
          <!--            <p>共享</p>-->
          <!--          </div>-->
          <Tooltip content="调试摄像头">
            <div class="option" style="margin-top: 20px" @click="handleCameraClick">
              <JYIcon href="#icon-gongxiangpingmu" />
              <p>摄像头</p>
            </div>
          </Tooltip>
          <!--          <Tooltip content="视频下载">-->
          <!--            <div class="option" style="margin-top: 20px" @click="handleDVideo(videoUrl)">-->
          <!--              <JYIcon href="#icon-daoru5"/>-->
          <!--              <p>下载</p>-->
          <!--            </div>-->
          <!--          </Tooltip>-->
        </div>
        <div class="live-video">
          <LiveVideo v-if="IsLiveEnd" :videoUrl="videoUrl" />
          <div v-if="!IsLiveEnd" class="center-page">
            <div id="local_stream" class="local-stream"></div>
          </div>
        </div>
      </div>
      <LiveTabs :tic-sdk="tim" :groupId="groupId" :isLogin="IsLoginByName" :msgs="msgs" />
    </div>
  </div>
</template>
<script>
import UserBar from '@components/UserBar'
import LiveVideo from './LiveVideo'
import LiveTabs from './LiveTabs'
import PaintBox from './paintbox/PaintBox'
import liveApi from '../../../api/live'
import { mapGetters } from 'vuex'
import TRTC from 'trtc-js-sdk'
import TIM from 'tim-js-sdk'
import addvideo from '../../common/edtiorVideoByLiveModal'

export default {
  components: {
    // RemoteJS,
    UserBar,
    LiveVideo,
    LiveTabs,
    PaintBox,
    addvideo
  },
  data: function() {
    return {
      isStart: false,
      loading: false,
      //默认推流摄像头
      isPushCamera: false,
      error: false,
      isFirst: false,
      showBoard: false,
      status: '',
      loginConfig: null,
      ticSdk: null,
      realRoomId: 0,
      section: {},
      ticSdkLoaded: false,
      cosLoaded: false,
      boardLoaded: false,
      webimLoaded: false,
      webrtcLoaded: false,
      localStream: '',
      remoteStream: '',
      IsAnchor: true,
      ticSdk1: null,
      tim: null,
      groupId: '',
      IsLoginByName: false,
      IsCReated: false,
      msgs: null,
      dayDate: '',
      IsLiveEnd: false,
      videoUrl: ''
    }
  },
  destroyed() {
    //退出音视频
    if (!this.IsLiveEnd && this.IsLoginByName) {
      this.endLive()
    }
  },
  created() {
    liveApi.getLiveSectionDetail(this.sectionId).then(res => {
      this.section = res.res
      this.dayDate = new Date()
    })
  },
  methods: {
    handleDVideo(url, name) {
      // 视频下载
      let _this = this
      if (url === '') return this.$message.warning('无视频流...')
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob' // 返回类型blob
      xhr.onload = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let blob = this.response
          // 转换一个blob链接
          let u = window.URL.createObjectURL(new Blob([blob]))
          let a = document.createElement('a')
          a.download = `${_this.section.name}.mp4` // 注意给文件加个后缀
          a.href = u
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        }
      }
      xhr.send()
    },
    judgeTime() {
      if (this.dayDate >= new Date(this.section.startTime)) {
        this.error = false
        this.status = '直播时间已到,请先调试摄像头...'
        return true
      } else {
        this.error = true
        this.status = '直播时间未到...'
        return false
      }
    },
    handleCameraClick() {
      // 检查摄像头设备
      this.status = '摄像头准备就绪...'
      TRTC.getCameras().then(devices => {
        if (devices.length === 0) {
          this.status = '摄像头开启失败...'
          this.error = true
          return false
        } else {
          this.status = '摄像头调试成功...'
          this.error = false
          return true
        }
      })
    },
    setLoginConfig(createData) {
      //第一次进入，只有 success 的消息
      if (!createData.anchorId) {
        return
      }
      this.loginConfig = TRTC.createClient({
        mode: 'videoCall',
        sdkAppId: createData.sdkAppId,
        userId: createData.anchorId,
        userSig: createData.userSig,
        streamId: String(createData.liveRoomId)
      })
      this.realRoomId = createData.liveRoomId
      // 注册远程监听,需在加入房间之前--这里发布订阅模式
      this.subScribeStream(this.loginConfig)
      // 初始化后才能加入房间
      this.joinRoom(this.loginConfig, this.realRoomId)
      // 即时通讯IM
      this.createdInitIM(createData)
    },

    createdInitIM(createData) {
      // 创建SDK TIM.create()
      let options = {
        SDKAppID: createData.sdkAppId // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
      }
      // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
      let tim = TIM.create(options) // SDK 实例通常用 tim 表示
      // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
      tim.setLogLevel(0) // 普通级别，日志量较多，接入时建议使用
      // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
      // 绑定监听事件
      this.bindEvent(tim, createData)
    },
    bindEvent(tim, createData) {
      let _this = this
      tim.on(TIM.EVENT.SDK_READY, function(event) {
        // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        // event.name - TIM.EVENT.SDK_READY
        _this.IsLoginByName = event.name !== ''
        //先通过房间号查找群组，若不存在，则创建
        tim
          .searchGroupByID(String(_this.realRoomId))
          .then(res => {
            _this.IsCReated = true
            _this.groupId = String(_this.realRoomId)
            _this.joinGroup(tim, _this.realRoomId)
          })
          .catch(err => {
            _this.IsCReated = false
            // 创建群组
            if (!_this.IsCReated) {
              tim
                .createGroup({
                  type: TIM.TYPES.GRP_PUBLIC, //公开群
                  name: `${_this.section.name}` || 'liveChat',
                  // name: 'chat',
                  groupID: String(_this.realRoomId),
                  joinOption: TIM.TYPES.JOIN_OPTIONS_FREE_ACCESS
                })
                .then(res => {
                  _this.groupId = res.data.group.groupID
                  _this.joinGroup(tim, _this.groupId)
                })
                .catch(() => {})
            }
          })
        // 设置自己的头像
        _this.updateMyProfile(tim)
      })

      tim.on(TIM.EVENT.MESSAGE_RECEIVED, msgs => {
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        // event.name - TIM.EVENT.MESSAGE_RECEIVED
        // event.data - 存储 Message 对象的数组 - [Message]
        _this.sortData(msgs.data)
      })

      // 开始登录
      this.loginTIM(tim, createData)
    },
    /**
     *  收消息
     */
    sortData(message) {
      message.forEach(m => {
        if (m.type !== 'TIMGroupSystemNoticeElem') {
          this.msgs = {
            send: m.nick,
            avatar: m.avatar,
            content: m.payload.text
          }
        }
      })
    },
    updateMyProfile(tim) {
      // 设置自己的头像
      tim
        .updateMyProfile({
          nick: this.userName,
          avatar: this.user.avatar
        })
        .then(() => {
          // 头像更新成功
        })
        .catch(() => {
          // 头像更新失败
        })
    },
    loginTIM(tim, createData) {
      this.tim = tim
      // 登录
      let loginData = {
        userID: String(createData.anchorId),
        userSig: createData.userSig
      }
      let promise = tim.login(loginData)
      //60012
      promise
        .then(res => {
          // 登录成功 返回
          if (res.data) {
            // this.$message.success('聊天系统登录成功!')
          }
        })
        .catch(err => {
          // 登录失败 返回
          // this.$message.warning(`${err}聊天系统登录失败...`)
        })
    },
    joinGroup(tim, groupId) {
      // 加入群组
      tim
        .joinGroup({ groupID: String(groupId) })
        .then(res => {
          if (res.data) {
            // this.$message.success(`${groupId}群组加入成功`)
          }
        })
        .catch(() => {
          // this.$message.success(`群组加入失败${err}`)
        })
    },
    handleBoardClick() {
      if (!this.ticSdk) {
        this.$message.warning('请先开始直播再使用白板')
        return
      }

      this.showBoard = !this.showBoard

      if (this.showBoard) {
        this.$nextTick(() => this.$refs.board.resize())
      }
    },
    handleScreenClick() {
      if (!this.isStart) {
        this.$message.warning('请先开始直播')
        return
      }

      this.$refs.liveVideo.pushScreen()
    },
    // 加入房间
    joinRoom(client, roomId) {
      this.status = '加入房间中...'
      client
        .join({ roomId })
        .catch(error => {
          this.$message.error(`进房失败${error}`)
        })
        .then(() => {
          // this.$message.success('进房成功')
          if (this.loginConfig) {
            this.error = false
            // 开始直播
            this.status = '直播连接中...'
            this.loading = true

            //创建本地流
            this.createStream(this.loginConfig.userId)
          } else {
            this.loading = false
            this.error = true
            return (this.status = '加入房间失败...')
          }
        })
    },
    // 创建本地音视频流
    createStream(userId) {
      const localStream = TRTC.createStream({
        userId,
        audio: true,
        video: true
      })
      this.localStream = localStream

      localStream
        .initialize()
        .catch(err => {
          // this.$message.error(`初始化本地流失败${err}`)
        })
        .then(() => {
          // this.remoteStream = `<view id="${'remote_stream-' +
          // 创建好后才能播放 本地流播放 localStream 是div的id
          this.localStream.play('local_stream')
          //创建好后才能发布
          this.publishStream(localStream, this.loginConfig)
        })
    },

    // 发布本地音视频流
    publishStream(localStream, client) {
      client
        .publish(localStream)
        .then(() => {
          // this.$message.success('本地流发布成功')
          this.status = '直播中...'
          this.error = false
          this.loading = false
          this.isStart = true
        })
        .catch(err => {
          this.$message.error(`本地流发布失败${err}`)
          // this.isStart = true
          this.status = '开启直播失败...'
          this.loading = false
          this.error = true
        })
    },

    // 播放远端流
    playStream(client) {
      client.on('stream-subscribed', event => {
        const remoteStream = event.stream
        this.$message.success(`远端流订阅成功${remoteStream.getId()}`)
        // 创建远端流标签，因为id是动态的，所以动态创建，用了v-html
        // this.remoteStream = `<view id="${'remote_stream-' +
        //   remoteStream.getId()}"  ></view>`

        // 做了dom操作 需要使用nextTick(),否则找不到创建的标签无法进行播放
        this.$nextTick(() => {
          // 播放
          this.IsAnchor = false
          // remoteStream.play(`remote_stream-${remoteStream.getId()}`)
          remoteStream.play('remote_stream')
        })
      })
    },
    enterVirtualRoom() {
      return liveApi.getVirtualRoom(this.sectionId).then(res => {
        // this.setLoginConfig(res.res)
        if (res.res === '当前小节直播已结束') {
          this.status = '录播中...'
          this.loading = false
          liveApi.getVirtual(this.sectionId).then(data => {
            if (data.res.liveState === 1) {
              this.videoUrl = data.res.videoUrl
              return (this.IsLiveEnd = true)
            }
          })
        } else {
          this.IsLiveEnd = false
          this.setLoginConfig(res.res)
          // if (this.judgeTime()) {
          //   // this.handleCameraClick() &&
          //   // 开始直播前先获取直播信息
          //   this.setLoginConfig(res.res)
          // } else {
          //   this.status = '时间未到...'
          //   this.error = true
          //   this.loading = false
          // }
        }
      })
    },
    beginLive() {
      this.loading = true
      this.enterVirtualRoom()
    },

    // 订阅远端流--加入房间前
    subScribeStream(client) {
      client.on('stream-added', event => {
        const remoteStream = event.stream
        // this.$message.success(`远端流增加${remoteStream.getId()}`)
        // 订阅远端流
        client.subscribe(remoteStream)
      })
    },
    /**
     * 结束直播
     */
    endLive() {
      // const _this = this
      this.error = false
      //退出音视频
      this.loginConfig
        .leave()
        .then(() => {
          // 停止本地流，关闭本地流内部的音视频播放器
          this.localStream.stop()
          // 关闭本地流，释放摄像头和麦克风访问权限
          this.localStream.close()
          this.localStream = null
          this.client = null
          this.isStart = false
          this.IsLoginByName = false
          // this.$message.success('房间退出成功')
          //退出直播
          liveApi.endLive(this.sectionId).then()
          // this.$message.success('直播结束')
          // 退房成功，可再次调用client.join重新进房开启新的通话。
          // this.joinRoom(this.loginConfig, this.realRoomId)
        })
        .catch(error => {
          // this.$message.error('退房失败 ' + error)
          // 错误不可恢复，需要刷新页面。
        })
      // 退出聊天
      this.tim
        .logout()
        .then(res => {
          if (res.data) {
            // this.$message.success('聊天功能已退出')
            // this.status = '聊天功能已退出'
          }
        })
        .catch(err => {
          // this.$message.success(`${err}聊天功能退出失败`)
        })
    }
  },
  computed: {
    roomId() {
      return Number(this.$route.query.courseId)
    },
    sectionId() {
      return Number(this.$route.query.sectionId)
    },
    ...mapGetters({
      userInfo: 'user/info'
    }),
    userId() {
      return this.userInfo.userId
    },
    userName() {
      return this.userInfo.realName
    },
    user() {
      return this.userInfo
    }
  },
  watch: {
    // dayDate(val) {
    //   this.dayDate = val
    //   this.judgeTime()
    // }
  }
}
</script>
<style lang="less" scoped>
@import '../../../theme/variables';

.live-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .top-bar {
    background: #2b343d;
    color: white;
    font-size: @font-size-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 70px;

    .live-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      flex: 3 1 75%;
      padding: 0 20px;
      .back-btn {
        margin-right: 20px;
        cursor: pointer;
        background: darken(#2b343d, 5%);
        border-radius: 3px;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: darken(#2b343d, 10%);
        }
      }

      .opt-container {
        button {
          padding: 2px 30px;
        }
      }
    }

    .user-container {
      flex: 1 0 25%;
      min-width: 200px;
    }
  }

  .content {
    background: #1c1f21;
    display: flex;
    flex: 1 1 auto;

    .live-content {
      display: flex;
      flex: 3 1 75%;

      .live-video {
        flex: 1 1 auto;
        background: black;
        border-radius: 4px;
        display: flex;
        position: relative;

        .fade-enter-active,
        .fade-leave-active {
          transition: opacity 0.5s;
        }
      }

      .live-option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: white;
        padding: 0 10px;

        .option {
          cursor: pointer;
        }

        svg {
          font-size: 16px;
        }
      }
    }

    .tab-container {
      flex: 1 0 25%;
    }
  }
}
.center-page {
  width: 100%;
  height: 100%;
  text-align: left;
  .local-stream {
    width: 100%;
    height: 100%;
  }
  .distant-stream {
    width: 100%;
    height: 100%;
  }
}
.editorModal {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    font-size: 16px;
  }
  .footer {
    text-align: center;
    margin-top: 20px;
    button {
      width: 86px;
      height: 30px;
    }
    button:first-child {
      margin-right: 15px;
    }
  }
}
</style>
