<template>
  <div class="video-container" ref="videoContainer">
    <LivePlayer :videoUrl="videoUrl" :options="playerOptions" />
  </div>
</template>
<script>
import LivePlayer from '@components/common/LivePlayer'

const PUSH_ROLE = {
  USER: 'user',
  SCREEN: 'screen'
}

export default {
  components: {
    LivePlayer
  },
  props: {
    videoUrl: {
      type: String
    }
  },
  data: function() {
    return {
      currentTime: 0,
      // camera: true,
      camera: false,
      microPhone: true,
      muted: false,
      fullscreen: false,
      playerOptions: {
        muted: false,
        fluid: true,
        language: 'zh-CN',
        poster: this.cover,
        controls: true,
        // autoplay: 'play',
        controlBar: {
          currentTimeDisplay: {},
          playToggle: true, // 可以暂停
          remainingTimeDisplay: false,
          progressControl: true, // 进度条
          fullscreenToggle: {},
          chaptersButton: false,
          descriptionsButton: false,
          subsCapsButton: false,
          audioTrackButton: false,
          volumePanel: {
            inline: false,
            vertical: true
          }
        },
        techOrder: ['html5']
      },
      stream: null,
      pushRole: PUSH_ROLE.USER,
      remoteStream: ''
    }
  },
  methods: {
    // 启动推流（摄像头）
    startRTC() {
      // 获取webrtc实例
      const WebRTC = this.ticSdk.getWebRTCInstance()
      const that = this
      WebRTC.getLocalStream(
        {
          audio: this.microPhone,
          video: this.camera
        },
        data => {
          that.handleLocalStream(data.stream, PUSH_ROLE.USER)
        },
        error => {
          that.$message.error('获取本地摄像头失败')
        }
      )
    }

    /**
     * 推屏幕分享
     */
    //   pushScreen() {
    //     const WebRTC = this.ticSdk.getWebRTCInstance()
    //     let screenSources = ['screen', 'window', 'tab']
    //     const that = this
    //     WebRTC.detectRTC(function(data) {
    //       if (!data.screenshare) {
    //         this.$message.warning('浏览器不支持屏幕分享，请切换到 Chrome 64 以上')
    //         return
    //       }
    //
    //       WebRTC.getLocalStream(
    //         {
    //           screen: true,
    //           screenSources: screenSources.join(','),
    //           attributes: {
    //             frameRate: 10
    //           }
    //         },
    //         data => {
    //           that.handleLocalStream(data.stream, PUSH_ROLE.SCREEN)
    //         },
    //         error => {
    //           that.$message.error('获取本地屏幕权限失败')
    //         }
    //       )
    //     })
    //   },
    //   handleLocalStream(stream, name) {
    //     const WebRTC = this.ticSdk.getWebRTCInstance()
    //     if (WebRTC.global.localStream && WebRTC.global.localStream.active) {
    //       this.updateStream(stream, name)
    //     } else {
    //       this.directPush(stream, name)
    //     }
    //   },
    //   updateStream(stream, name) {
    //     const WebRTC = this.ticSdk.getWebRTCInstance()
    //     const that = this
    //     WebRTC.updateStream(
    //       {
    //         role: name,
    //         stream: stream.clone()
    //       },
    //       () => {
    //         // 成功
    //         that.$emit('on-success')
    //         that.pushRole = name
    //       },
    //       error => {
    //         that.$message.error(`更新流失败, ${error}`)
    //         that.$emit('on-error')
    //       }
    //     )
    //   },
    //   directPush(stream, name) {
    //     const WebRTC = this.ticSdk.getWebRTCInstance()
    //     const that = this
    //     WebRTC.startRTC(
    //       {
    //         stream: stream.clone(),
    //         role: name
    //       },
    //       data => {
    //         // 成功
    //         that.$emit('on-success')
    //         that.pushRole = name
    //       },
    //       error => {
    //         that.$message.error(`推流失败, ${error}`)
    //         that.$emit('on-error')
    //       }
    //     )
    //   },
    //   /**
    //    * 摄像头开关
    //    */
    //   toggleCamera() {
    //     this.camera = !this.camera
    //     this.ticSdk.enableCamera(this.camera)
    //   },
    //
    //   /**
    //    * 麦克风开关
    //    */
    //   toggleMic() {
    //     this.microPhone = !this.microPhone
    //     this.ticSdk.enableMic(this.microPhone)
    //   }
    // },
    // filters: {
    //   duration(time) {
    //     const m = parseInt(time / 60)
    //     const s = time % 60
    //     return `${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`
    //   }
    // },
    // watch: {
    //   ticSdk(ticSdk) {
    //     if (!ticSdk) {
    //       return
    //     }
    //
    //     ticSdk.on(TICSDK.CONSTANT.EVENT.WEBRTC.INIT_ERROR, err => {
    //       this.$message.error('WebRTC初始化失败')
    //       this.$emit('on-error')
    //     })
    //
    //     ticSdk.on(TICSDK.CONSTANT.EVENT.WEBRTC.LOCAL_STREAM_ADD, data => {
    //       this.stream = data.stream
    //       this.$emit('on-start')
    //     })
    //
    //     this.ticSdk.on(TICSDK.CONSTANT.EVENT.WEBRTC.ERROR_NOTIFY, data => {
    //       this.$message.error('音视频链接断开，请重新开始直播')
    //       this.$emit('on-error')
    //     })
    //   },
    //   cover(val) {
    //     this.playerOptions.poster = val
    //   },
    //   pushRole(newVal, oldVal) {
    //     if (oldVal !== newVal) {
    //       this.$emit('on-push-type-change')
    //     }
    //   }
  }
}
</script>
<style lang="less" scoped>
.video-container {
  flex: 1 1 auto;
  background: black;
  border-radius: 4px;

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .controls {
    height: 30px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      font-size: 16px;
      cursor: pointer;
      margin: 0 4px;
    }
  }
}
</style>
